import {IGraphqlClient} from '@wix/graphql-client';
import {ControllerFlowAPI, IHttpClient} from '@wix/yoshi-flow-editor';
import {createAutoGraphqlClient} from '../utils/autoGraphqlClient';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {getMultilingualHeader} from '../utils/ambassador.utils';
import {GetCheckoutDocument} from '../../gql/graphql';

interface ParamsGenerationPrerequisites {
  siteStore: SiteStore;
  flowAPI: ControllerFlowAPI;
  currency?: string;
}
export class AutoGraphqlApi {
  private readonly autoGraphqlClient: IGraphqlClient;

  constructor({httpClient}: {httpClient: IHttpClient}) {
    this.autoGraphqlClient = createAutoGraphqlClient(httpClient);
  }

  public async getCheckout(id: string, paramsGenerationPrerequisites?: ParamsGenerationPrerequisites) {
    const params = /* istanbul ignore next */ getParams(paramsGenerationPrerequisites);
    return this.autoGraphqlClient.query(GetCheckoutDocument, {checkoutId: id}, params);
  }
}

function getParams(paramsGenerationPrerequisites?: ParamsGenerationPrerequisites) {
  /* istanbul ignore next */
  if (paramsGenerationPrerequisites) {
    return {headers: getHeaders(paramsGenerationPrerequisites)};
  }
  /* istanbul ignore next */
  return undefined;
}

/* istanbul ignore next */
function getHeaders({currency, flowAPI, siteStore}: ParamsGenerationPrerequisites) {
  const currencyHeader = currency ? {'x-wix-currency': currency} : {};
  const multilingualHeader = getMultilingualHeader(flowAPI, siteStore);
  return {...currencyHeader, ...multilingualHeader};
}
