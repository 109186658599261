import {list as getAddressesInfo, update as updateMemberInfo} from '@wix/ambassador-addresses-api-v1-address/http';
import {ambassadorWithHeaders} from '../utils/ambassador.utils';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {MemberAddressesInfoModel} from '../models/MemberAddressesInfo.model';
import {ADDRESSES_APP_DEFINITION_ID} from '@wix/wixstores-client-core';
import {mapToUpdateMemberAddressRequest} from '../utils/billingDetails.utils';
import {FullAddressContactDetails, ApiAddress} from '@wix/ambassador-ecom-v1-checkout/types';

export class MemberService {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly siteStore: SiteStore;
  public readonly currency?: string;
  public addressesInfo: MemberAddressesInfoModel = {
    addresses: [],
  };
  public isAddressesAppInstalled!: boolean;
  public currentUserEmail: string = '';

  constructor({siteStore, flowAPI, currency}: {flowAPI: ControllerFlowAPI; siteStore: SiteStore; currency?: string}) {
    this.siteStore = siteStore;
    this.flowAPI = flowAPI;
    this.currency = currency;
  }

  public isMember(): boolean {
    return Boolean(this.flowAPI.controllerConfig.wixCodeApi.user.currentUser.loggedIn);
  }

  public async fetchAddressesInfo(): Promise<void> {
    const {data} = await ambassadorWithHeaders(getAddressesInfo({}), this.siteStore, this.flowAPI, this.currency);
    this.addressesInfo = new MemberAddressesInfoModel(data);
  }

  public async setIsAddressesAppInstalled(): Promise<void> {
    this.isAddressesAppInstalled = await this.flowAPI.controllerConfig.wixCodeApi.site.isAppSectionInstalled({
      sectionId: 'my_addresses',
      appDefinitionId: ADDRESSES_APP_DEFINITION_ID,
    });
  }

  public async getCurrentUserEmail(): Promise<void> {
    this.currentUserEmail = await this.flowAPI.controllerConfig.wixCodeApi.user.currentUser.getEmail();
  }
  public async updateMemberInfo(
    contactDetails: FullAddressContactDetails,
    address: ApiAddress,
    addressesServiceId: string
  ): Promise<void> {
    const {mappedMemberDetailsInfo, fieldMasks} = mapToUpdateMemberAddressRequest(
      contactDetails,
      address,
      addressesServiceId
    );

    await ambassadorWithHeaders(
      updateMemberInfo({address: mappedMemberDetailsInfo, fieldMask: fieldMasks, setAsDefault: false}),
      this.siteStore,
      this.flowAPI,
      this.currency
    );
    await this.fetchAddressesInfo();
  }
}
